html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
i,
li {
  color: #fff;
}

* {
  box-sizing: border-box;
}

.react-datepicker-wrapper {
  background: #232f3d;
  border: 1px solid #a6b5bd !important;
  box-sizing: border-box;
  border-radius: 6px;
  width: auto !important;
}

.react-datepicker-wrapper input {
  background: none;
  padding: 7px 16px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.006em;
  color: rgba(255, 255, 255, 0.6);
  border: none;
}
.react-datepicker__input-container {
  border: none;
}

.selectNotify {
  height: auto;
  text-align: right;
}
